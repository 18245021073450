import React from "react";
import {FieldProps, FormikState} from "formik";
import {FormItemProps} from "antd/lib/form";
import {Form, Select as AntSelect, Input as AntInput} from "antd";

export type SelectOption = {
    label: string
    value: string
}

type CustomFormItemProps = {
    type: string
    showValidateIcon?: boolean
    formItemClassName?: string
    LeftComponent?: React.ComponentType
    selectOptions?: Array<SelectOption>
} & FormItemProps & FieldProps & FormikState<any>

export default function createFormItem(Component: any) {
    return function (props: CustomFormItemProps) {
        const {
            selectOptions,
            LeftComponent,
            formItemClassName = null,
            showValidateIcon = true,
            ...rest
        } = props;
        const {
            field,
            form,
            label,
            submitCount,
            type,
        } = rest;

        const touched = form.touched[field.name] as boolean;
        const submitted = submitCount > 0;
        const hasError = form.errors[field.name];
        const submittedError = hasError && submitted;
        const touchedError = hasError && touched;
        const onInputChange = ({target: {value}}) => form.setFieldValue(field.name, value);
        const onChange = value => form.setFieldValue(field.name, value);
        const onBlur = () => form.setFieldTouched(field.name, true);

        function getValidateStatus() {
            if (showValidateIcon === false) return

            if (submittedError || touchedError) {
                return "error"
            }

            return "success"
        }

        return (
            <Form.Item label={label}
                       className={formItemClassName}
                       hasFeedback={submitted || touched}
                       validateStatus={getValidateStatus()}
                       help={submittedError || touchedError ? hasError : false}>
                {typeof LeftComponent !== "undefined" && <LeftComponent/>}
                <Component {...field} {...rest}
                           onBlur={onBlur}
                           onChange={type ? onInputChange : onChange}>
                    {selectOptions && selectOptions.map(({label, value}) => {
                        return <AntSelect.Option value={value} key={value}>{label}</AntSelect.Option>
                    })}
                </Component>
            </Form.Item>
        );
    }
}

export const Input = createFormItem(AntInput);
export const TextArea = createFormItem(AntInput.TextArea);
export const Select = createFormItem(AntSelect);
